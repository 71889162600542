<div class="container2" [hidden]="loadPage">
  <div class="flex2">
    <div class="loader2"></div>
  </div>
  <div class="load-text2">
    <span></span>
  </div>
</div>

<mat-card
  class="example-card"
  style="margin-top: 10px"
  [hidden]="showTableProd"
>
  <mat-card-header>
    <!-- <mat-card-title *ngIf="TableType == 1">{{
        "show.RegInSrv" | translate
      }}</mat-card-title>
      <mat-card-title *ngIf="TableType == 2">{{
        "show.RedInCourse" | translate
      }}</mat-card-title>
      <mat-card-title *ngIf="TableType == 3">{{
        "show.RegOrders" | translate
      }}</mat-card-title> -->
    <!-- <mat-card-title>{{
      "Registration.Registration" | translate
    }}</mat-card-title> -->
    <mat-card-title>{{
      "Registration.ServicesOrd" | translate
    }}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-card class="example-card">
      <mat-card-header>
        <mat-card-title>
          {{ "show.Show" | translate }}
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="row">
          <div class="col-lg-12 col-sm-12">
            <form class="example-form">
              <div class="row">
                <div class="col-lg-6 col-sm-12">
                  <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>{{ "Registration.Name" | translate }}</mat-label>
                    <input
                      type="text"
                      matInput
                      [formControl]="regestrationCtrl"
                      [matAutocomplete]="autoreg"
                      autocomplete="anyword"
                      [matAutocompleteTrigger]="autoreg"
                      (click)="openPanelOfRegestration()"
                    />
                    <mat-autocomplete
                      #autoreg="matAutocomplete"
                      (optionSelected)="getRegValue($event.option.value)"
                    >
                      <mat-option (click)="getAllRegestration()">{{
                        "show.All"  | translate
                      }}</mat-option>
                      <mat-option
                        *ngFor="let srv of filteredRestiration | async"
                        [value]="srv.lvl_name"
                      >
                        <span>{{ srv.lvl_name }}</span>
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>{{ "show.Service" | translate }}</mat-label>
                    <input
                      type="text"
                      matInput
                      [formControl]="serviceN_jaCtrl"
                      [matAutocomplete]="autosrvNJ"
                      autocomplete="anyword"
                      [matAutocompleteTrigger]="autosrvNJ"
                      (click)="openPanelOfServicesOfN_Ja()"
                    />
                    <mat-autocomplete
                      #autosrvNJ="matAutocomplete"
                      (optionSelected)="getValue($event.option.value)"
                    >
                      <mat-option (click)="getAllServicesNJa()">{{
                        "show.All" | translate
                      }}</mat-option>
                      <mat-option
                        *ngFor="let srv of filteredServiceN_Ja | async"
                        [value]="srv.postTitle"
                      >
                        <span>{{ srv.postTitle }}</span>
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
              </div>
            </form>
            <!-- <div *ngIf="TableType == 1" class="col-lg-9 col-sm-12">
              <form class="example-form">
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>{{ "show.Service" | translate }}</mat-label>
                  <input
                    type="text"
                    matInput
                    [formControl]="serviceCtrl"
                    [matAutocomplete]="autosrv"
                    autocomplete="anyword"
                    [matAutocompleteTrigger]="autosrv"
                    (click)="openPanelOfServices()"
                  />
                  <mat-autocomplete
                    #autosrv="matAutocomplete"
                    (optionSelected)="getValue($event.option.value)"
                  >
                    <mat-option (click)="getAllServices()">{{
                      "show.All" | translate
                    }}</mat-option>
                    <mat-option
                      *ngFor="let srv of filteredService | async"
                      [value]="srv.postTitle"
                    >
                      <span>{{ srv.postTitle }}</span>
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </form>
            </div> -->
            <!-- <div *ngIf="TableType == 2" class="col-lg-3 col-sm-12">
              <form class="example-form">
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>{{ "show.Country" | translate }}</mat-label>
                  <input
                    type="text"
                    matInput
                    [formControl]="countryCtrl"
                    [matAutocomplete]="countryauto"
                    (click)="openPanelOfCountry()"
                  />
                  <mat-autocomplete #countryauto="matAutocomplete">
                    <mat-option (click)="(getAllCountries_Courses)">{{
                      "show.All" | translate
                    }}</mat-option>
                    <mat-option
                      *ngFor="let country_ of filteredCountry | async"
                      [value]="country_.postTitle"
                      (click)="getDropDownCourcesData(country_.postID)"
                    >
                      <span>{{ country_.postTitle }}</span>
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </form>
            </div>
            <div *ngIf="TableType == 2" class="col-lg-5 col-sm-12">
              <form class="example-form">
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>{{ "show.CourseName" | translate }}</mat-label>
                  <input
                    type="text"
                    matInput
                    [formControl]="courcesCtrl"
                    [matAutocomplete]="courseauto"
                    (click)="openPanelOfCourses()"
                  />
                  <mat-autocomplete
                    #courseauto="matAutocomplete"
                    (optionSelected)="getValueOfCourse($event.option.value)"
                  >
                    <mat-option
                      *ngFor="let course of filteredCources | async"
                      [value]="course.postTitle"
                    >
                      <span>{{ course.postTitle }}</span>
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </form>
            </div>
            <div *ngIf="TableType == 2" class="col-lg-4 col-sm-12">
              <mat-form-field appearance="fill">
                <mat-label style="float: right">{{
                  "show.Choose" | translate
                }}</mat-label>
                <input
                  matInput
                  [matDatepicker]="picker"
                  (dateChange)="updateDate($event)"
                />
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle
                  class="mat-toggle"
                  matIconSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div> -->

            <!-- <div *ngIf="TableType == 3" class="col-lg-3 col-sm-12">
              <form class="example-form">
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>{{ "show.Department" | translate }}</mat-label>
                  <input
                    type="text"
                    matInput
                    [formControl]="departmentCtrl"
                    [matAutocomplete]="departmentauto"
                  />
                  <mat-autocomplete #departmentauto="matAutocomplete">
                    <mat-option
                      *ngFor="let department_ of filteredDepartment | async"
                      [value]="department_.DeptName"
                      (click)="getDropDownSpecializationData(department_.BrnId)"
                    >
                      <span>{{ department_.DeptName }}</span>
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </form>
            </div>
            <div *ngIf="TableType == 3" class="col-lg-4 col-sm-12">
              <form class="example-form">
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>{{ "show.LvlMain" | translate }}</mat-label>
                  <input
                    type="text"
                    matInput
                    [formControl]="SpecializationCtrl"
                    [matAutocomplete]="specializationauto"
                  />
                  <mat-autocomplete
                    #specializationauto="matAutocomplete"
                    (optionSelected)="filtering($event.option.value)"
                  >
                    <mat-option
                      *ngFor="let special of filteredSpecialization | async"
                      [value]="special.postTitle"
                    >
                      <span>{{ special.postTitle }}</span>
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </form>
            </div>
            <div *ngIf="TableType == 3" class="col-lg-2 col-sm-12">
              <mat-form-field
                appearance="fill"
                style="width: 100%; margin: 0; margin-right: 10px"
              >
                <mat-label>{{ "show.regyear" | translate }}</mat-label>
                <mat-select [formControl]="year" [(value)]="selectedYear">
                  <mat-option *ngFor="let y of yearsArr" [value]="y">{{
                    y
                  }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div *ngIf="TableType == 3" class="col-lg-5 col-sm-12">
              <mat-form-field
                appearance="fill"
                style="width: 100%; margin: 0; margin-right: 10px"
              >
                <mat-label>{{ "show.Department" | translate }}</mat-label>
                <mat-select
                  (valueChange)="onChangeDepartment()"
                  matNativeControl
                  [(value)]="selectedDepartment"
                >
                  <mat-option (click)="getAll()">{{
                    "show.All" | translate
                  }}</mat-option>
                  <mat-option
                    *ngFor="let dept of dataSourceDepartments"
                    [value]="dept.BrnId"
                  >
                    {{ dept.DeptName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div *ngIf="TableType == 3" class="col-lg-5 col-sm-12">
              <mat-form-field
                appearance="fill"
                style="width: 100%; margin: 0; margin-right: 10px"
              >
                <mat-label>{{ "show.LvlMain" | translate }}</mat-label>
                <mat-select matNativeControl [(value)]="selectedSpecialization">
                  <mat-option>{{ "show.chooseSpecial" | translate }}</mat-option>
                  <mat-option
                    *ngFor="let special of dataSourceSpecializations"
                    [value]="special.postTitle"
                  >
                    {{ special.postTitle }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div> -->
          </div>
        </div>
      </mat-card-content>

      <mat-card-actions>
        <p style="text-align: center">
          <button
            style="margin-left: 10px"
            style="
              bottom: 60%;
              margin-left: 10px;
              background-color: blue;
              z-index: 2;
            "
            matTooltip="عرض"
            class="float"
            mat-fab
            color="primary"
            (click)="filtering(selectedRegistration,selectedServices)"
          >
            {{ "btn.show" | translate }}
          </button>
          <!-- <button
              *ngIf="TableType == 1"
              style="margin-left: 10px"
              style="
                bottom: 54%;
                margin-left: 10px;
                background-color: blue;
                z-index: 2;
              "
              matTooltip="عرض"
              class="float"
              mat-fab
              color="primary"
              (click)="filtering(selectedServices, '')"
            >
              {{ "btn.show" | translate }}
            </button> -->
          <!-- <button
              *ngIf="TableType == 2"
              style="margin-left: 10px"
              style="
                bottom: 54%;
                margin-left: 10px;
                background-color: blue;
                z-index: 2;
              "
              matTooltip="عرض"
              class="float"
              mat-fab
              color="primary"
              (click)="filtering(selectedCourse, searchDate)"
            >
              {{ "btn.show" | translate }}
            </button> -->
          <!-- <button
              *ngIf="TableType == 3"
              style="margin-left: 10px"
              style="
                bottom: 54%;
                margin-left: 10px;
                background-color: blue;
                z-index: 2;
              "
              matTooltip="عرض"
              class="float"
              mat-fab
              color="primary"
              (click)="filtering(selectedSpecialization, selectedYear)"
            >
              {{ "btn.show" | translate }}
            </button> -->

          <button
            matTooltip="تصدير"
            mat-fab
            style="
              bottom: 50%;
              margin-left: 10px;
              background-image: url(../../../assets/images/xls.png);
              z-index: 2;
              height: 54px;
              width: 54px;
              background-repeat: no-repeat;
              background-size: 48px;
              background-color: #0c7238;
              background-position: center;
            "
            class="float"
            color="primary"
            (click)="btnExport()"
          ></button>
          <button
            mat-fab
            matTooltip="تصدير"
            style="
              bottom: 40%;
              margin-left: 10px;
              background-image: url(../../../assets/images/pdf.png);
              z-index: 2;
              height: 54px;
              width: 54px;
              background-repeat: no-repeat;
              background-position: center;
              background-size: 48px;
              background-color: #e53935;
            "
            class="float"
            color="primary"
            data-toggle="modal" (click)="showPanelF()"
            data-target="#myModal"
          ></button>
        </p>
      </mat-card-actions>
    </mat-card>
    <div style="margin: 20px">
      <p style="text-align: center">
        {{ "btn.show" | translate }}

        <select (change)="getPage($event)">
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="250">250</option>
          <option value="500">500</option>
        </select>
      </p>
      <!-- <table
          *ngIf="TableType == 1"
          id="excel-table"
          style="
            width: 100%;
            max-width: 100%;
            margin-bottom: 1.143rem;
            background-color: transparent;
          "
          class="table table-bordered table-striped"
        >
          <thead>
            <tr>
              <th style="text-align: center" scope="col">#</th>
              <th style="text-align: center" scope="col">
                {{ "show.OrganizationName" | translate }}
              </th>
              <th style="text-align: center" scope="col">
                {{ "show.Specialization" | translate }}
              </th>
              <th style="text-align: center" scope="col">
                {{ "show.PhoneNumber" | translate }}
              </th>
              <th style="text-align: center" scope="col">
                {{ "show.YourEmail" | translate }}
              </th>
              <th style="text-align: center" scope="col">
                {{ "show.Service" | translate }}
              </th>
              <th style="text-align: center" scope="col">
                {{ "show.City" | translate }}
              </th>
              <th style="text-align: center" scope="col">
                {{ "show.ServiceDescription" | translate }}
              </th>
            </tr>
          </thead>
          <tbody cdkDropList style="text-align: center !important">
            <tr *ngFor="let item of ShowData; let i = index" cdkDrag>
              <th scope="row">{{ i + 1 }}</th>
              <td>{{ item.com_name }}</td>
              <td>{{ item.com_domain }}</td>
              <td>{{ item.com_phone }}</td>
              <td>{{ item.com_email }}</td>
              <td>{{ item.service }}</td>
              <td>{{ item.city }}</td>
              <td>{{ item.service_disc }}</td>
            </tr>
          </tbody>
        </table>
  
        <table
          *ngIf="TableType == 2"
          id="excel-table"
          style="
            width: 100%;
            max-width: 100%;
            margin-bottom: 1.143rem;
            background-color: transparent;
          "
          class="table table-bordered table-striped"
        >
          <thead>
            <tr>
              <th style="padding: 3px; text-align: center" scope="col">#</th>
              <th style="padding: 3px; text-align: center" scope="col">
                {{ "show.CourseName" | translate }}
              </th>
              <th style="padding: 3px; text-align: center" scope="col">
                {{ "show.date" | translate }}
              </th>
              <th style="padding: 3px; text-align: center" scope="col">
                {{ "show.stdName" | translate }}
              </th>
              <th style="padding: 3px; text-align: center" scope="col">
                {{ "show.PhoneNumber" | translate }}
              </th>
              <th style="padding: 3px; text-align: center" scope="col">
                {{ "show.YourEmail" | translate }}
              </th>
            </tr>
          </thead>
          <tbody cdkDropList style="text-align: center !important">
            <tr *ngFor="let item of ShowData; let i = index" cdkDrag>
              <th style="padding: 3px" scope="row">{{ i + 1 }}</th>
              <td style="padding: 3px">{{ item.lvl_name }}</td>
              <td style="padding: 3px">{{ item.lvl_date }}</td>
              <td style="padding: 3px">{{ item.std_name }}</td>
              <td style="padding: 3px">{{ item.std_phone }}</td>
              <td style="padding: 3px">{{ item.std_email }}</td>
            </tr>
          </tbody>
        </table>
        <div style="overflow-x: auto; padding: 0 0 0 2px">
          <table
            *ngIf="TableType == 3"
            id="excel-table"
            style="
              width: max-content;
              max-width: max-content;
              margin-bottom: 1.143rem;
              background-color: transparent;
            "
            class="table table-bordered table-striped"
          >
            <thead>
              <tr>
                <th style="text-align: center" scope="col">#</th>
                <th style="text-align: center" scope="col">
                  {{ "show.stdName" | translate }}
                </th>
                <th style="text-align: center" scope="col">
                  {{ "show.Gender" | translate }}
                </th>
                <th style="text-align: center" scope="col">
                  {{ "show.Nationality" | translate }}
                </th>
                <th style="text-align: center" scope="col">
                  {{ "show.BirthPlace" | translate }}
                </th>
                <th style="text-align: center" scope="col">
                  {{ "show.BirthDate" | translate }}
                </th>
                <th style="text-align: center" scope="col">
                  {{ "show.QualificationType" | translate }}
                </th>
                <th style="text-align: center" scope="col">
                  {{ "show.Average" | translate }}
                </th>
                <th style="text-align: center" scope="col">
                  {{ "show.DateQualification" | translate }}
                </th>
                <th style="text-align: center" scope="col">
                  {{ "show.PlaceQualification" | translate }}
                </th>
                <th style="text-align: center" scope="col">
                  {{ "show.School" | translate }}
                </th>
                <th style="text-align: center" scope="col">
                  {{ "show.StudyType" | translate }}
                </th>
                <th style="text-align: center" scope="col">
                  {{ "show.PhoneNumber" | translate }}
                </th>
                <th style="text-align: center" scope="col">
                  {{ "show.Address" | translate }}
                </th>
                <th style="text-align: center" scope="col">
                  {{ "show.YourEmail" | translate }}
                </th>
                <th style="text-align: center" scope="col">
                  {{ "show.LvlMain" | translate }}
                </th>
                <th style="text-align: center" scope="col">
                  {{ "show.TypeIdCard" | translate }}
                </th>
                <th style="text-align: center" scope="col">
                  {{ "show.IdCardNumber" | translate }}
                </th>
                <th style="text-align: center" scope="col">
                  {{ "show.PlaceIdCard" | translate }}
                </th>
              </tr>
            </thead>
            <tbody cdkDropList style="text-align: center !important">
              <tr *ngFor="let item of ShowData; let i = index" cdkDrag>
                <th scope="row">{{ i + 1 }}</th>
                <td>{{ item.std_name }}</td>
                <td>{{ item.std_gender }}</td>
                <td>{{ item.std_nationality }}</td>
                <td>{{ item.std_place_birthday }}</td>
                <td>{{ item.std_date_birthday }}</td>
                <td>{{ item.std_type_qualification }}</td>
                <td>{{ item.std_average }}</td>
                <td>{{ item.std_date_qualification }}</td>
                <td>{{ item.std_place_qualification }}</td>
                <td>{{ item.std_school }}</td>
                <td>{{ item.std_type_study }}</td>
                <td>{{ item.std_phone }}</td>
                <td>{{ item.std_address }}</td>
                <td>{{ item.std_email }}</td>
                <td>{{ item.std_lvl_main }}</td>
                <td>{{ item.std_type_id_card }}</td>
                <td>{{ item.std_id_card_number }}</td>
                <td>{{ item.std_palce_id_card }}</td>
              </tr>
            </tbody>
          </table>
          <h2
            *ngIf="NoRecords"
            style="text-align: center; font-family: 'Droid Arabic Kufi'"
          >
            {{ "show.NoRecords" | translate }}
          </h2>
        </div> -->
      <!-- <div style="overflow-x: auto; padding: 0 0 0 2px">
        <table
          id="excel_table"
          style="
            width: max-content;
            max-width: max-content;
            margin-bottom: 1.143rem;
            background-color: transparent;
          "
          class="table table-bordered table-striped"
        >
          <thead>
            <tr>
              <th style="text-align: center" scope="col">#</th>
              <th style="text-align: center" scope="col">
                {{ "Registration.RegID" | translate }}
              </th>
              <th style="text-align: center" scope="col">
                {{ "Registration.compName" | translate }}
              </th>
              <th style="text-align: center" scope="col">
                {{ "Registration.DelegateName" | translate }}
              </th>
              <th style="text-align: center" scope="col">
                {{ "Registration.City" | translate }}
              </th>
              <th style="text-align: center" scope="col">
                {{ "Registration.PartnerNum" | translate }}
              </th>
              <th style="text-align: center" scope="col">
                {{ "Registration.PartnersNames" | translate }}
              </th>
              <th style="text-align: center" scope="col">
                {{ "Registration.Mobil1" | translate }}
              </th>
              <th style="text-align: center" scope="col">
                {{ "Registration.companyActivity" | translate }}
              </th>
              <th style="text-align: center" scope="col">
                {{ "Registration.Website" | translate }}
              </th>
              <th style="text-align: center" scope="col">
                {{ "Registration.Email" | translate }}
              </th>
              <th style="text-align: center" scope="col">
                {{ "Registration.Nationality" | translate }}
              </th>
              <th style="text-align: center" scope="col">
                {{ "Registration.Address" | translate }}
              </th>
              <th style="text-align: center" scope="col">
                {{ "Registration.InvestmentLicenseNo" | translate }}
              </th>
              <th style="text-align: center" scope="col">
                {{ "Registration.CommercialRegNo" | translate }}
              </th>
              <th style="padding: 3px; text-align: center" scope="col">
                {{ "our-team.Processes" | translate }}
              </th>
            </tr>
          </thead>
          <tbody cdkDropList style="text-align: center !important">
            <tr
              *ngFor="
                let item of ShowData | searchByName : 'lvl_name' : searchText;
                let i = index
              "
              cdkDrag
            >
              <th scope="row">{{ i + 1 }}</th>
              <td>{{ item.reg_std_no }}</td>
              <td>{{ item.lvl_name }}</td>
              <td>{{ item.std_name }}</td>
              <td>{{ item.std_governorate }}</td>
              <td>{{ item.std_average }}</td>
              <td>{{ item.std_date_birthday }}</td>
              <td>{{ item.std_palce_id_card }}</td>
              <td>{{ item.std_phone }}</td>
              <td>{{ item.std_place_qualification }}</td>
              <td>{{ item.std_email }}</td>
              <td>{{ item.std_nationality }}</td>
              <td>{{ item.std_address }}</td>
              <td>{{ item.std_id_card_number }}</td>
              <td>{{ item.std_school }}</td>
              <td style="padding: 3px; width: 150px">
                <button
                  style="margin-left: 3px"
                  matTooltipPosition="above"
                  matTooltip="تعديل"
                  (click)="showDataToUpdate( item.rowNumber)"
                  mat-mini-fab
                  color="primary"
                  aria-label="Example icon button with a menu icon"
                >
                  <mat-icon>edit</mat-icon>
                </button>
                <button
                  matTooltipPosition="above"
                  matTooltip="حذف"
                  (click)="delete(template, item.reg_std_id)"
                  mat-mini-fab
                  color="accent"
                  aria-label="Example icon button with a plus one icon"
                >
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <h2
          *ngIf="NoRecords"
          style="text-align: center; font-family: 'Droid Arabic Kufi'"
        >
          {{ "show.NoRecords" | translate }}
        </h2>
      </div> -->
      <div style="overflow-x: auto; padding: 0 0 0 2px">
        <table
          id="excel_table"
          style="
            width: max-content;
            max-width: max-content;
            margin-bottom: 1.143rem;
            background-color: transparent;
          "
          class="table table-bordered table-striped"
        >
          <thead>
            <tr>
              <th style="text-align: center" scope="col">#</th>
              <th style="text-align: center" scope="col">
                {{ "Registration.Name" | translate }}
              </th>
              <th style="text-align: center" scope="col">
                {{ "Registration.Mobil1" | translate }}
              </th>
              <th style="text-align: center" scope="col">
                {{ "show.IdCardNumber" | translate }}
              </th>
              <th style="text-align: center" scope="col">
                {{ "Registration.Email" | translate }}
              </th>
              <th style="text-align: center" scope="col">
                {{ "Registration.reqServices" | translate }}
              </th>
              <th style="text-align: center" scope="col">
                {{ "Registration.Message" | translate }}
              </th>
              <th style="padding: 3px; text-align: center" scope="col">
                {{ "our-team.Processes" | translate }}
              </th>
            </tr>
          </thead>
          <tbody style="text-align: center !important; font-size: 12px">
            <tr *ngFor="let item of ShowData; let i = index">
              <th scope="row">{{ i + 1 }}</th>
              <td>{{ item.lvl_name }}</td>
              <td>{{ item.std_phone }}</td>
              <td>{{ item.std_id_card_number }}</td>
              <td>{{ item.std_email }}</td>
              <td [innerHTML]="item.std_gender"></td>
              <td>{{ item.std_nationality }}</td>
              <td style="padding: 3px; width: 150px">
                <button
                  style="margin-left: 3px"
                  matTooltipPosition="above"
                  matTooltip="عرض"
                  (click)="viewData(item.rowNumber)"
                  mat-mini-fab
                  color="primary"
                  aria-label="Example icon button with a menu icon"
                >
                  <mat-icon>remove_red_eye</mat-icon>
                </button>
                <button
                  style="margin-left: 3px"
                  matTooltipPosition="above"
                  matTooltip="تعديل"
                  (click)="showDataNJToUpdate(item.rowNumber)"
                  mat-mini-fab
                  color="primary"
                  aria-label="Example icon button with a menu icon"
                >
                  <mat-icon>edit</mat-icon>
                </button>
                <button
                  matTooltipPosition="above"
                  matTooltip="حذف"
                  (click)="delete(template, item.reg_std_id)"
                  mat-mini-fab
                  color="accent"
                  aria-label="Example icon button with a plus one icon"
                >
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <h2
          *ngIf="NoRecords"
          style="text-align: center; font-family: 'Droid Arabic Kufi'"
        >
          {{ "show.NoRecords" | translate }}
        </h2>
      </div>

      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li *ngFor="let item of pageNumbers" class="page-item">
            <button (click)="getRows(item.numPage)" class="page-link">
              {{ item.namePage }}
            </button>
          </li>
        </ul>
      </nav>
    </div>
  </mat-card-content>
</mat-card>

<!-- <ng-container>
  <mat-card class="example-card" *ngIf="showDataPanle">
    <mat-card-header>
      <mat-card-title>{{
        "Registration.Registration" | translate
      }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-tab-group animationDuration="1000ms">
        <mat-tab label="{{ 'Registration.RegisteredData' | translate }}">
          <div style="margin-top: 14px">
            <div class="col-lg-12">
              <div class="row" style="margin-bottom: 30; margin-top: 20px">
                <div class="col-lg-6 col-sm-12 col-xs-12">
                  <mat-form-field class="example-full-width">
                    <mat-label
                      >{{ "Registration.compName" | translate }}
                    </mat-label>
                    <input autocomplete="off" [(ngModel)]="CompName" matInput />
                    <mat-icon style="color: #374bab" matSuffix>edit</mat-icon>
                  </mat-form-field>
                </div>
                <div class="col-lg-6 col-sm-12 col-xs-12">
                  <mat-form-field class="example-full-width">
                    <mat-label
                      >{{ "Registration.DelegateName" | translate }}
                    </mat-label>
                    <input
                      autocomplete="off"
                      [(ngModel)]="DelegateName"
                      matInput
                    />
                    <mat-icon style="color: #374bab" matSuffix>edit</mat-icon>
                  </mat-form-field>
                </div>
                <div class="col-lg-6 col-sm-12 col-xs-12">
                  <mat-form-field class="example-full-width">
                    <mat-label
                      >{{ "Registration.City" | translate }}
                    </mat-label>
                    <input autocomplete="off" [(ngModel)]="City" matInput />
                    <mat-icon style="color: #374bab" matSuffix>edit</mat-icon>
                  </mat-form-field>
                </div>
                <div class="col-lg-6 col-sm-12 col-xs-12">
                  <mat-form-field class="example-full-width">
                    <mat-label
                      >{{ "Registration.PartnerNum" | translate }}
                    </mat-label>
                    <input
                      autocomplete="off"
                      [(ngModel)]="PartnerNum"
                      matInput
                    />
                    <mat-icon style="color: #374bab" matSuffix>edit</mat-icon>
                  </mat-form-field>
                </div>
                <div class="col-lg-6 col-sm-12 col-xs-12">
                  <mat-form-field class="example-full-width">
                    <mat-label
                      >{{ "Registration.PartnersNames" | translate }}
                    </mat-label>
                    <input
                      autocomplete="off"
                      [(ngModel)]="PartnersNames"
                      matInput
                    />
                    <mat-icon style="color: #374bab" matSuffix>edit</mat-icon>
                  </mat-form-field>
                </div>
                <div class="col-lg-6 col-sm-12 col-xs-12">
                  <mat-form-field class="example-full-width">
                    <mat-label
                      >{{ "Registration.Mobil1" | translate }}
                    </mat-label>
                    <input autocomplete="off" [(ngModel)]="Mobil1" matInput />
                    <mat-icon style="color: #374bab" matSuffix>edit</mat-icon>
                  </mat-form-field>
                </div>
                <div class="col-lg-6 col-sm-12 col-xs-12">
                  <mat-form-field class="example-full-width">
                    <mat-label
                      >{{ "Registration.Mobil2" | translate }}
                    </mat-label>
                    <input autocomplete="off" [(ngModel)]="Mobil2" matInput />
                    <mat-icon style="color: #374bab" matSuffix>edit</mat-icon>
                  </mat-form-field>
                </div>
                <div class="col-lg-6 col-sm-12 col-xs-12">
                  <mat-form-field class="example-full-width">
                    <mat-label
                      >{{ "Registration.Website" | translate }}
                    </mat-label>
                    <input autocomplete="off" [(ngModel)]="Website" matInput />
                    <mat-icon style="color: #374bab" matSuffix>edit</mat-icon>
                  </mat-form-field>
                </div>
                <div class="col-lg-6 col-sm-12 col-xs-12">
                  <mat-form-field class="example-full-width">
                    <mat-label
                      >{{ "Registration.Email" | translate }}
                    </mat-label>
                    <input autocomplete="off" [(ngModel)]="Email" matInput />
                    <mat-icon style="color: #374bab" matSuffix>edit</mat-icon>
                  </mat-form-field>
                </div>
                <div class="col-lg-6 col-sm-12 col-xs-12">
                  <mat-form-field class="example-full-width">
                    <mat-label
                      >{{ "Registration.Nationality" | translate }}
                    </mat-label>
                    <input
                      autocomplete="off"
                      [(ngModel)]="Nationality"
                      matInput
                    />
                    <mat-icon style="color: #374bab" matSuffix>edit</mat-icon>
                  </mat-form-field>
                </div>
                <div class="col-lg-6 col-sm-12 col-xs-12">
                  <mat-form-field class="example-full-width">
                    <mat-label
                      >{{ "Registration.Address" | translate }}
                    </mat-label>
                    <input autocomplete="off" [(ngModel)]="Address" matInput />
                    <mat-icon style="color: #374bab" matSuffix>edit</mat-icon>
                  </mat-form-field>
                </div>
                <div class="col-lg-6 col-sm-12 col-xs-12">
                  <mat-form-field class="example-full-width">
                    <mat-label
                      >{{ "Registration.InvestmentLicenseNo" | translate }}
                    </mat-label>
                    <input
                      autocomplete="off"
                      [(ngModel)]="InvestmentLicenseNo"
                      matInput
                    />
                    <mat-icon style="color: #374bab" matSuffix>edit</mat-icon>
                  </mat-form-field>
                </div>
                <div class="col-lg-6 col-sm-12 col-xs-12">
                  <mat-form-field class="example-full-width">
                    <mat-label
                      >{{ "Registration.CommercialRegNo" | translate }}
                    </mat-label>
                    <input
                      autocomplete="off"
                      [(ngModel)]="CommercialRegNo"
                      matInput
                    />
                    <mat-icon style="color: #374bab" matSuffix>edit</mat-icon>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>

  <mat-card-actions>
    <p style="text-align: center">
      <button
        *ngIf="showUpdate"
        style="margin-left: 10px"
        style="
          bottom: 30%;
          margin-left: 10px;
          background-color: green;
          z-index: 2;
        "
        class="float"
        mat-fab
        color="primary"
        (click)="update()"
      >
        {{ "btn.update" | translate }}
      </button>

      <button
        *ngIf="showcancel"
        matTooltip="الغاء العملية"
        style="
          bottom: 40%;
          margin-left: 10px;
          background-color: orangered;
          z-index: 2;
        "
        class="float"
        (click)="btnclose()"
        mat-fab
        color="primary"
      >
        {{ "btn.close" | translate }}
      </button>
    </p>
  </mat-card-actions>
</ng-container> -->

<ng-container>
  <mat-card class="example-card" *ngIf="viewDataPanle">
    <mat-card-header>
      <mat-card-title>{{
        "Registration.ServicesOrd" | translate
      }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-tab-group animationDuration="1000ms">
        <mat-tab label="{{ 'Registration.RegisteredData' | translate }}">
          <div style="margin-top: 14px">
            <div class="col-lg-12">
              <div class="row" style="margin-bottom: 30; margin-top: 20px">
                <div class="col-lg-4 col-sm-12 col-xs-12">
                  <mat-form-field class="example-full-width">
                    <mat-label
                      >{{ "Registration.Name" | translate }}
                    </mat-label>
                    <input
                      autocomplete="off"
                      [(ngModel)]="NameOrder"
                      matInput readonly
                    />
                  </mat-form-field>
                </div>
                <div class="col-lg-4 col-sm-12 col-xs-12">
                  <mat-form-field class="example-full-width">
                    <mat-label
                      >{{ "Registration.Mobil1" | translate }}
                    </mat-label>
                    <input autocomplete="off" [(ngModel)]="Mobil1NJ" matInput readonly/>
                  </mat-form-field>
                </div>
                <div class="col-lg-4 col-sm-12 col-xs-12">
                  <mat-form-field class="example-full-width">
                    <mat-label
                      >{{ "show.IdCardNumber" | translate }}
                    </mat-label>
                    <input
                      autocomplete="off"
                      [(ngModel)]="IdCardNumberNJ"
                      matInput readonly
                    />
                  </mat-form-field>
                </div>
                <div class="col-lg-6 col-sm-12 col-xs-12">
                  <mat-form-field class="example-full-width">
                    <mat-label
                      >{{ "Registration.Email" | translate }}
                    </mat-label>
                    <input autocomplete="off" [(ngModel)]="EmailNJ" matInput readonly/>
                  </mat-form-field>
                </div>
                <div class="col-lg-6 col-sm-12 col-xs-12">
                  <mat-form-field class="example-full-width">
                    <mat-label
                      >{{ "Registration.Message" | translate }}
                    </mat-label>
                    <textarea
                      autocomplete="off"
                      [(ngModel)]="MessageNJ"
                      matInput readonly
                    ></textarea>
                  </mat-form-field>
                </div>
                <div class="col-lg-12 col-sm-12 col-xs-12">
                  <div class="row">
                    <div
                      class="col-lg-6 col-sm-12 col-xs-12"
                      *ngFor="let item of ServicesN_Ja"
                    >
                      <!-- <div *ngFor="let req of reqServicesNJ2"> -->

                      <mat-checkbox disabled
                        [(ngModel)]="item.checked"
                      >
                        {{ item.postTitle }}
                      </mat-checkbox>
                      <!-- </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>

  <mat-card-actions>
    <p style="text-align: center">
      <button
        *ngIf="showUpdate"
        style="margin-left: 10px"
        style="
          bottom: 30%;
          margin-left: 10px;
          background-color: green;
          z-index: 2;
        "
        class="float"
        mat-fab
        color="primary"
        (click)="updateNJ()"
      >
        {{ "btn.update" | translate }}
      </button>

      <button
        *ngIf="showcancel"
        matTooltip="الغاء العملية"
        style="
          bottom: 40%;
          margin-left: 10px;
          background-color: orangered;
          z-index: 2;
        "
        class="float"
        (click)="btnclose()"
        mat-fab
        color="primary"
      >
        {{ "btn.close" | translate }}
      </button>
    </p>
  </mat-card-actions>
</ng-container>
<ng-container>
  <mat-card class="example-card" *ngIf="showDataPanle">
    <mat-card-header>
      <mat-card-title>{{
        "Registration.ServicesOrd" | translate
      }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-tab-group animationDuration="1000ms">
        <mat-tab label="{{ 'Registration.RegisteredData' | translate }}">
          <div style="margin-top: 14px">
            <div class="col-lg-12">
              <div class="row" style="margin-bottom: 30; margin-top: 20px">
                <div class="col-lg-4 col-sm-12 col-xs-12">
                  <mat-form-field class="example-full-width">
                    <mat-label
                      >{{ "Registration.Name" | translate }}
                    </mat-label>
                    <input
                      autocomplete="off"
                      [(ngModel)]="NameOrder"
                      matInput
                    />
                    <mat-icon style="color: #374bab" matSuffix>edit</mat-icon>
                  </mat-form-field>
                </div>
                <div class="col-lg-4 col-sm-12 col-xs-12">
                  <mat-form-field class="example-full-width">
                    <mat-label
                      >{{ "Registration.Mobil1" | translate }}
                    </mat-label>
                    <input autocomplete="off" [(ngModel)]="Mobil1NJ" matInput />
                    <mat-icon style="color: #374bab" matSuffix>edit</mat-icon>
                  </mat-form-field>
                </div>
                <div class="col-lg-4 col-sm-12 col-xs-12">
                  <mat-form-field class="example-full-width">
                    <mat-label
                      >{{ "show.IdCardNumber" | translate }}
                    </mat-label>
                    <input
                      autocomplete="off"
                      [(ngModel)]="IdCardNumberNJ"
                      matInput
                    />
                    <mat-icon style="color: #374bab" matSuffix>edit</mat-icon>
                  </mat-form-field>
                </div>
                <div class="col-lg-6 col-sm-12 col-xs-12">
                  <mat-form-field class="example-full-width">
                    <mat-label
                      >{{ "Registration.Email" | translate }}
                    </mat-label>
                    <input autocomplete="off" [(ngModel)]="EmailNJ" matInput />
                    <mat-icon style="color: #374bab" matSuffix>edit</mat-icon>
                  </mat-form-field>
                </div>
                <div class="col-lg-6 col-sm-12 col-xs-12">
                  <mat-form-field class="example-full-width">
                    <mat-label
                      >{{ "Registration.Message" | translate }}
                    </mat-label>
                    <textarea
                      autocomplete="off"
                      [(ngModel)]="MessageNJ"
                      matInput
                    ></textarea>
                    <mat-icon style="color: #374bab" matSuffix>edit</mat-icon>
                  </mat-form-field>
                </div>
                <div class="col-lg-12 col-sm-12 col-xs-12">
                  <div class="row">
                    <div
                      class="col-lg-6 col-sm-12 col-xs-12"
                      *ngFor="let item of ServicesN_Ja"
                    >
                      <!-- <div *ngFor="let req of reqServicesNJ2"> -->

                      <mat-checkbox
                        [(ngModel)]="item.checked"
                      >
                        {{ item.postTitle }}
                      </mat-checkbox>
                      <!-- </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>

  <mat-card-actions>
    <p style="text-align: center">
      <button
        *ngIf="showUpdate"
        style="margin-left: 10px"
        style="
          bottom: 30%;
          margin-left: 10px;
          background-color: green;
          z-index: 2;
        "
        class="float"
        mat-fab
        color="primary"
        (click)="updateNJ()"
      >
        {{ "btn.update" | translate }}
      </button>

      <button
        *ngIf="showcancel"
        matTooltip="الغاء العملية"
        style="
          bottom: 40%;
          margin-left: 10px;
          background-color: orangered;
          z-index: 2;
        "
        class="float"
        (click)="btnclose()"
        mat-fab
        color="primary"
      >
        {{ "btn.close" | translate }}
      </button>
    </p>
  </mat-card-actions>
</ng-container>

<ng-template #template>
  <div class="modal-body text-center">
    <p>هل انت متأكد من اكمال العملية المطلوبة؟</p>
    <button type="button" class="btn btn-default" (click)="confirm()">
      تأكيد
    </button>
    <button type="button" class="btn btn-primary" (click)="decline()">
      الغاء
    </button>
  </div>
</ng-template>

<!-- Modal -->
<!-- <div class="conatiner">
<div class="modal fade" id="myModal" role="dialog" #childModal="bs-modal" bsModal>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-body">
        <div style="overflow-x: auto; padding: 0 0 0 2px">
          <table
            id="pdf_table"
            style="
              width: max-content;
              max-width: max-content;
              margin-bottom: 1.143rem;
              background-color: transparent;
            "
            class="table table-bordered table-striped"
          >
            <thead>
              <tr>
                <th style="text-align: center;font-size: 12px;" scope="col">#</th>
                <th style="text-align: center;font-size: 12px;" scope="col">
                  {{ "Registration.RegID" | translate }}
                </th>
                <th style="text-align: center;font-size: 12px;" scope="col">
                  {{ "Registration.compName" | translate }}
                </th>
                <th style="text-align: center;font-size: 12px;" scope="col">
                  {{ "Registration.DelegateName" | translate }}
                </th>
                <th style="text-align: center;font-size: 12px;" scope="col">
                  {{ "Registration.City" | translate }}
                </th>
                <th style="text-align: center;font-size: 12px;" scope="col">
                  {{ "Registration.PartnerNum" | translate }}
                </th>
                <th style="text-align: center;font-size: 12px;" scope="col">
                  {{ "Registration.PartnersNames" | translate }}
                </th>
                <th style="text-align: center;font-size: 12px;" scope="col">
                  {{ "Registration.Mobil1" | translate }}
                </th>
                <th style="text-align: center;font-size: 12px;" scope="col">
                  {{ "Registration.companyActivity" | translate }}
                </th>
                <th style="text-align: center;font-size: 12px;" scope="col">
                  {{ "Registration.Website" | translate }}
                </th>
                <th style="text-align: center;font-size: 12px;" scope="col">
                  {{ "Registration.Email" | translate }}
                </th>
                <th style="text-align: center;font-size: 12px;" scope="col">
                  {{ "Registration.Nationality" | translate }}
                </th>
                <th style="text-align: center;font-size: 12px;" scope="col">
                  {{ "Registration.Address" | translate }}
                </th>
                <th style="text-align: center;font-size: 12px;" scope="col">
                  {{ "Registration.InvestmentLicenseNo" | translate }}
                </th>
                <th style="text-align: center;font-size: 12px;" scope="col">
                  {{ "Registration.CommercialRegNo" | translate }}
                </th>
              </tr>
            </thead>
            <tbody cdkDropList style="text-align: center !important;font-size: 12px;">
              <tr
                *ngFor="
                  let item of ShowData;
                  let i = index
                "
                
              >
                <th scope="row">{{ i + 1 }}</th>
                <td style="font-size: 12px;">{{ item.reg_std_no }}</td>
                <td style="font-size: 12px;">{{ item.lvl_name }}</td>
                <td style="font-size: 12px;">{{ item.std_name }}</td>
                <td style="font-size: 12px;">{{ item.std_governorate }}</td>
                <td style="font-size: 12px;">{{ item.std_average }}</td>
                <td style="font-size: 12px;">{{ item.std_date_birthday }}</td>
                <td style="font-size: 12px;">{{ item.std_palce_id_card }}</td>
                <td style="font-size: 12px;">{{ item.std_phone }}</td>
                <td style="font-size: 12px;">{{ item.std_place_qualification }}</td>
                <td style="font-size: 12px;">{{ item.std_email }}</td>
                <td style="font-size: 12px;">{{ item.std_nationality }}</td>
                <td style="font-size: 12px;">{{ item.std_address }}</td>
                <td style="font-size: 12px;">{{ item.std_id_card_number }}</td>
                <td style="font-size: 12px;">{{ item.std_school }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="download()" data-dismiss="modal">{{ "btn.export" | translate }}</button>
        <button type="button" class="btn btn-default" data-dismiss="modal">{{ "btn.close" | translate }}</button>
      </div>
    </div>
  </div>
</div>
</div> -->

<div class="conatiner" *ngIf="showPanel">
  <div
    class="modal fade"
    id="myModal" tabindex="-1"
    role="dialog"
    #childModal="bs-modal"
    bsModal
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-body">
          <div style="overflow-x: auto; padding: 0 0 0 2px">
            <table
              id="pdf_table"
              style="
                width: max-content;
                max-width: max-content;
                margin-bottom: 1.143rem;
                background-color: transparent;
              "
              class="table table-bordered table-striped"
            >
              <thead>
                <tr>
                  <th style="text-align: center; font-size: 12px" scope="col">
                    #
                  </th>
                  <th style="text-align: center; font-size: 12px" scope="col">
                    {{ "Registration.Name" | translate }}
                  </th>
                  <th style="text-align: center; font-size: 12px" scope="col">
                    {{ "Registration.Mobil1" | translate }}
                  </th>
                  <th style="text-align: center; font-size: 12px" scope="col">
                    {{ "show.IdCardNumber" | translate }}
                  </th>
                  <th style="text-align: center; font-size: 12px" scope="col">
                    {{ "Registration.Email" | translate }}
                  </th>
                  <th style="text-align: center; font-size: 12px" scope="col">
                    {{ "Registration.reqServices" | translate }}
                  </th>
                  <th style="text-align: center; font-size: 12px" scope="col">
                    {{ "Registration.Message" | translate }}
                  </th>
                </tr>
              </thead>
              <tbody style="text-align: center !important; font-size: 12px">
                <tr *ngFor="let item of ShowData; let i = index">
                  <th scope="row">{{ i + 1 }}</th>
                  <td style="font-size: 12px">{{ item.std_name }}</td>
                  <td style="font-size: 12px">{{ item.std_governorate }}</td>
                  <td style="font-size: 12px">{{ item.std_phone }}</td>
                  <td style="font-size: 12px">{{ item.std_email }}</td>
                  <td style="font-size: 12px">{{ item.std_nationality }}</td>
                  <td style="font-size: 12px">{{ item.std_id_card_number }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            (click)="download()"
            data-dismiss="modal"
          >
            {{ "btn.export" | translate }}
          </button>
          <button type="button" class="btn btn-default" data-dismiss="modal">
            {{ "btn.close" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
